import React from 'react';
import PropTypes from 'prop-types';

import '../styles/base.css';
import '../styles/fluid.css';
import '../styles/compositions.css';
import '../styles/extend.css';

const Layout = ({ children, location }) => (
  <>
    <main id="main">
      {children}
    </main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

export default Layout;
